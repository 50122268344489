import React from "react"

import { useShopify } from "../../hooks/useShopify"

export const withGiftcard = Component => ({ name = "Giftcard", page, liveVariants }: any) => {
  const { edgeNormaliser } = useShopify()

  const { headerContent, settingGiftCardProduct, additionalGiftCardTitle, additionalGiftCardDescription, additionalBalanceTitle, footerContent } =
    page || {}

  let rawProduct = undefined
  if (settingGiftCardProduct.shopify?.raw) {
    try {
      rawProduct = JSON.parse(settingGiftCardProduct.shopify?.raw)
    } catch (e) {
      console.error("Error parsing raw shopify product")
    }
  }

  const variants = edgeNormaliser(liveVariants?.product?.variants || rawProduct.variants)

  Component.displayName = name
  return (
    <Component
      headerContent={headerContent}
      additionalGiftCardTitle={additionalGiftCardTitle}
      additionalGiftCardDescription={additionalGiftCardDescription}
      additionalBalanceTitle={additionalBalanceTitle}
      footerContent={footerContent}
      product={liveVariants?.product || rawProduct.variants}
      giftCardVariants={variants}
    />
  )
}
