import React from "react"
import { withGiftCardForm } from "./withGiftCardForm"
import { TextField } from "./TextField/TextField"

import { Select } from "../../Select/Select"
import { Fields, Selector, Label, Checkboxes, Description, StyledCheckbox, StyledTextArea, AddToCart } from "./GiftCardFormStyles"
import { SmallBody } from "../../Styled/Text"
import { StyledLabelPlaceholder, StyledLabelPlaceholderSpaced } from "../../Styled/Form"

export const GiftCardForm = withGiftCardForm(
  ({
    data,
    handleChange,
    settingDisablePhysicalGiftcard,
    settingDisableDigitalGiftcard,
    additionalGiftCardSenderLabel,
    additionalGiftCardReceiverLabel,
    additionalGiftCardTypeLabel,
    giftCardType,
    handleGiftCardTypeChange,
    giftCardTypeDigital,
    giftCardTypePhysical,
    additionalDigitalGiftCardLabel,
    additionalDigitalGiftCardDescription,
    additionalPhysicalGiftCardLabel,
    additionalPhysicalGiftCardDescription,
    additionalGiftCardReceiverEmailLabel,
    additionalGiftCardConfirmReceiverEmailLabel,
    amount,
    handleAmountChange,
    amountOptions,
    additionalDigitalGiftCardSelectAmountLabel,
    additionalPhysicalGiftCardSelectAmountLabel,
    settingGiftCardMaxLength,
    settingSenderMaxLength,
    additionalGiftCardMessageLabel,
    additionalGiftCardLengthMessage,
    handleAddToCart,
    loading,
    disabled,
  }) => {
    const [emailError, setEmailError] = React.useState("")

    const validateBeforeSubmit = e => {
      if (data?.receiverEmail !== data?.confirmReceiverEmail) {
        setEmailError("Emails must match.")
        e?.preventDefault()
      } else {
        setEmailError("")
        handleAddToCart(e)
      }
    }

    return (
      <form onSubmit={validateBeforeSubmit}>
        <TextField
          name="senderName"
          type="text"
          value={data?.senderName}
          onChange={handleChange}
          label={additionalGiftCardSenderLabel}
          limit={settingSenderMaxLength}
          lengthLimitMessage={additionalGiftCardLengthMessage}
          required
          spacing
        />
        <TextField
          name="receiverName"
          type="text"
          value={data?.receiverName}
          onChange={handleChange}
          label={additionalGiftCardReceiverLabel}
          limit={settingSenderMaxLength}
          lengthLimitMessage={additionalGiftCardLengthMessage}
          required
          spacing
        />
        <Selector>
          <StyledLabelPlaceholderSpaced>{additionalGiftCardTypeLabel}</StyledLabelPlaceholderSpaced>
          <Checkboxes>
            {!settingDisableDigitalGiftcard ? (
              <StyledCheckbox
                label={additionalDigitalGiftCardLabel}
                active={giftCardType === giftCardTypeDigital}
                handleChange={() => handleGiftCardTypeChange(giftCardTypeDigital)}
                layout={"circle"}
              />
            ) : null}

            {!settingDisablePhysicalGiftcard ? (
              <StyledCheckbox
                label={additionalPhysicalGiftCardLabel}
                active={giftCardType === giftCardTypePhysical}
                handleChange={() => handleGiftCardTypeChange(giftCardTypePhysical)}
                layout={"circle"}
              />
            ) : null}
          </Checkboxes>
          {giftCardType === giftCardTypeDigital && additionalDigitalGiftCardDescription?.length > 0 ? (
            <Description>{additionalDigitalGiftCardDescription}</Description>
          ) : null}
          {giftCardType === giftCardTypePhysical && additionalPhysicalGiftCardDescription?.length > 0 ? (
            <Description>{additionalPhysicalGiftCardDescription}</Description>
          ) : null}
        </Selector>
        {giftCardType === giftCardTypeDigital ? (
          <Fields className="mb-3">
            <TextField
              name="receiverEmail"
              type="email"
              value={data?.receiverEmail}
              onChange={handleChange}
              label={additionalGiftCardReceiverEmailLabel}
              required
              spacing
              title={"Please enter a valid email"}
              errors={emailError}
            />
            <TextField
              name="confirmReceiverEmail"
              type="email"
              value={data?.confirmReceiverEmail}
              onChange={handleChange}
              label={additionalGiftCardConfirmReceiverEmailLabel}
              required
              spacing
              title={"Please enter a valid email"}
              errors={emailError}
            />
          </Fields>
        ) : null}
        <Fields>
          <Select
            name="amount"
            value={amount}
            onChange={handleAmountChange}
            layout={"form"}
            options={amountOptions}
            label={giftCardType === giftCardTypeDigital ? additionalDigitalGiftCardSelectAmountLabel : additionalPhysicalGiftCardSelectAmountLabel}
            spacing
            required
          />
        </Fields>
        <StyledTextArea
          name="message"
          type="text"
          value={data?.message}
          onChange={handleChange}
          label={additionalGiftCardMessageLabel}
          limit={settingGiftCardMaxLength}
          lengthLimitMessage={additionalGiftCardLengthMessage}
        />
        <AddToCart disabled={loading || disabled} size="primary" wide type="submit">
          Add to Bag
        </AddToCart>
      </form>
    )
  }
)
