import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { Giftcard as Page } from "../components/Giftcard/Giftcard"
import { Error } from "../components/Error/Error"
import { useCore } from "../hooks/useCore"
import { useQueries } from "../hooks/useQueries"
import { useShopify } from "../hooks/useShopify"
import { useApp } from "../hooks/useApp"
import { useLocation } from "../hooks/useLocation"

export const query = graphql`
  query {
    page: sanityPageGiftcard {
      title
      headerContent: _rawHeaderContent(resolveReferences: { maxDepth: 8 })
      footerContent: _rawFooterContent(resolveReferences: { maxDepth: 8 })
      settingGiftCardProduct: settingGiftCardProduct {
        shopify {
          id: shopifyId
          handle: shopifyHandle
          raw: shopifyRaw
        }
        tags
      }
      additionalGiftCardTitle
      additionalGiftCardDescription: _rawAdditionalGiftCardDescription(resolveReferences: { maxDepth: 2 })
      additionalGiftCardCustomAmountLabel
      additionalGiftCardCustomAmountPlaceholder
      additionalGiftCardReceiverEmailLabel
      additionalGiftCardConfirmReceiverEmailLabel
      additionalGiftCardMessageLabel
      additionalGiftCardLengthMessage
      additionalBalanceTitle
      additionalBalanceFormTitle
      additionalBalanceFormCardNumberLabel
      additionalBalanceFormPinLabel
      additionalBalanceFormCheckButtonText
      additionalBalanceResultTitle
      additionalBalanceResultMoneyLabel
      additionalBalanceResultExpiryLabel
      additionalBalanceResultCardNumberLabel
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      unavailableMessage: _rawUnavailableMessage(resolveReferences: { maxDepth: 2 })
      unavailableTitle
    }
    errorPage: sanityPageError {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      link: _rawLink(resolveReferences: { maxDepth: 2 })
      collection: _rawCollection(resolveReferences: { maxDepth: 3 })
      products: _rawProducts(resolveReferences: { maxDepth: 3 })
      featuredLink: _rawFeaturedLink(resolveReferences: { maxDepth: 2 })
      featuredTitle
    }
  }
`

const Component = ({ data, ...props }) => {
  const {
    helpers: { encodeShopifyId },
  } = useCore()
  const { location } = useLocation()

  const {
    localQueries: { GET_PRODUCT_AVAILABILITY },
  } = useQueries()

  const { useLazyQuery } = useShopify()

  const {
    page: {
      settingGiftCardProduct: {
        shopify: { id, handle },
      },
    },
  } = data

  const [getVariants, { data: liveVariants, called: getVariantsCalled, loading, error }] = useLazyQuery(GET_PRODUCT_AVAILABILITY, {
    fetchPolicy: `cache-and-network`,
    nextFetchPolicy: "cache-first",
    variables: {
      id: encodeShopifyId(id, "Product"),
      handle,
      countryCode: location,
      firstVariants: 2,
    },
  })

  useEffect(() => {
    if (!handle || getVariantsCalled) return
    getVariants()
  }, [handle, getVariantsCalled, getVariants])

  if (loading) return <div>Loading...</div>
  if (!liveVariants?.product || location !== "AU")
    return <Error page={data?.errorPage} content={data?.page?.unavailableMessage} overrideTitle={data?.page?.unavailableTitle} />
  if (error) console.error(error)
  return <Page {...props} liveVariants={liveVariants} loading={loading} {...data} />
}
export default Component
