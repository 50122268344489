import React from "react"

import { StyledInputWrapper, StyledLabel, StyledTextArea } from "../Styled/Form"
import { withTextArea } from "./withTextArea"
import { TextLengthLimitationMessage } from "./TextAreaStyles"

export const TextArea = withTextArea(
  ({
    label,
    name,
    value,
    limit = undefined,
    lengthLimitMessage = "",
    className = "",
    ...props
  }) => (
    <StyledInputWrapper className={className}>
      <StyledLabel className="sr-only" htmlFor={name}>{label}</StyledLabel>
      <StyledTextArea placeholder={label} id={name} name={name} maxLength={limit} {...props} />
      {Boolean(limit) && Boolean(lengthLimitMessage) ? (
        <TextLengthLimitationMessage>
          {lengthLimitMessage.replace(
            "${amount}",
            (limit - value.length).toString(),
          )}
        </TextLengthLimitationMessage>
      ) : null}
    </StyledInputWrapper>
  ),
)
