import React from "react"

import { StyledInput, StyledInputWrapper, StyledLabel } from "../../../Styled/Form"
import { withTextField } from "./withTextField"
import { TextLengthLimitationMessage, ErrorMessage } from "./TextFieldStyles"

export const TextField = withTextField(({ label, name, value, limit, errors, lengthLimitMessage, ...props }) => (
  <StyledInputWrapper marginTop={"small"}>
    {/* <StyledLabel htmlFor={name}>{label}</StyledLabel> */}
    <StyledInput spacing id={name} name={name} value={value} placeholder={label} maxLength={limit} {...props} />
    {Boolean(limit) && Boolean(lengthLimitMessage) ? (
      <TextLengthLimitationMessage>{lengthLimitMessage.replace("${amount}", (limit - value.length).toString())}</TextLengthLimitationMessage>
    ) : (
      <div className="mb-2"></div>
    )}
    {typeof errors === "string" && <ErrorMessage>{errors}</ErrorMessage>}
  </StyledInputWrapper>
))
