import React from "react"

import { withGiftcard } from "./withGiftcard"
import { Wrapper, Section, Left, Right, Subtitle } from "./GiftcardStyles"
import { Sections } from "../Sections/Sections"
import { RichText } from "../RichText/RichText"
import { GiftCardForm } from "./Form/GiftCardForm"
import { GiftCardBalance } from "./Balance/GiftCardBalance"

export const Giftcard = withGiftcard(
  ({
    headerContent,
    additionalGiftCardTitle,
    additionalGiftCardDescription,
    additionalBalanceTitle,
    footerContent,
    giftCardVariants,
    product,
  }): JSX.Element => (
    <>
      <Sections page={{ content: headerContent }} />
      <Wrapper>
        <Section>
          <Left>
            <Subtitle>{additionalGiftCardTitle}</Subtitle>
            <RichText>{additionalGiftCardDescription}</RichText>
          </Left>
          <Right>
            <GiftCardForm product={product} giftCardVariants={giftCardVariants} />
          </Right>
        </Section>
        <Section id="check_balance">
          <Left>
            <Subtitle>{additionalBalanceTitle}</Subtitle>
          </Left>
          <Right>
            <GiftCardBalance />
          </Right>
        </Section>
      </Wrapper>
      <Sections page={{ content: footerContent }} />
    </>
  )
)
