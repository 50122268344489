import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  p-2 md:p-4
`

export const Top = tw.div`
  flex flex-col md:flex-row justify-between pb-2-4 border-b border-black border-opacity-20
`

export const Title = tw.h4`
  text-14 leading-1.71
`

export const CardNumber = tw.p`
  text-14 leading-1.71 text-black text-opacity-60
`

export const Bottom = tw.div`
  flex flex-row justify-start pt-2-4
`

export const Section = styled.div`
  ${tw`flex flex-col px-4 items-start`}
  :first-of-type {
    ${tw`pl-0 border-r border-black border-opacity-20`}
  }
  :last-of-type {
    ${tw`pr-0`}
  }
`

export const SectionTitle = tw.h5`
  text-14 leading-1.71 uppercase font-semibold tracking-10 mb-0-8
`

export const Balance = tw.span`
  flex flex-row items-start
`

export const Price = styled.p`
  ${({ large }) => (large ? tw`text-50 leading-none tracking-10 font-bold` : tw`text-20 leading-1.5 tracking-5 font-semibold`)}
`

export const Expiry = tw.p`
  text-14 leading-1.71
`
